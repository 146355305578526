$font-path: "../fonts/" !default;

@font-face{
    font-family: "nexa";
    font-style: normal;
        src:url("#{$font-path}nexa/nexa_thin.woff")format("woff"),
            url("#{$font-path}nexa/nexa_thin.ttf") format("truetype");
        font-weight: 200;
      }
  
@font-face {
    font-family: 'nexa-slab';
    src:url("#{$font-path}/nexa_slab/nexa_slab_200.woff")format("woff"),
        url("#{$font-path}/nexa_slab/nexa_slab_200.ttf") format("truetype"),
        url("#{$font-path}/nexa_slab/nexa_slab_200.svg") format("svg");
    font-weight: 200;
  }