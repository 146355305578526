@font-face {
  font-family: nexa;
  font-style: normal;
  src: url("../fonts/nexa_thin.woff") format("woff"), url("../fonts/nexa_thin.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: nexa-slab;
  src: url("../fonts/nexa_slab_200.woff") format("woff"), url("../fonts/nexa_slab_200.ttf") format("truetype"), url("../fonts/nexa_slab_200.svg") format("svg");
  font-weight: 200;
}
/*# sourceMappingURL=fonts.0c259239.css.map */
